.container {
  height: 160px;
  width: 380px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 5px #00000059;
  border-radius: 12px;
  display: flex;
}

.colorBar {
    width: 80px;
    height: 100%;
    border-radius: 12px 0px 0px 12px;
}

.course {
    width: 100%;
    margin: 16px 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lenght {
    font-family: "Sarabun", serif;
    font-size: 16px;
}