.contentListContainer {
    display: flex;
    justify-content: space-evenly;
    margin: 3rem 0;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.header h1, .header h2, .header h3 {
    line-height: 1.5;
} 

.contentList {
    display: flex;
    flex-direction: column;
    font-family: 'Sarabun';
    list-style: circle;
    margin: 1rem auto;
    line-height: 1.8;
}

.contentList h2 {
    margin-bottom: 1rem;
}

.contentWrapper {
    margin: 5rem auto;
}

.contentListContainer a {
    color: black;
    text-decoration: underline;
}

.contentListContainer a:visited {
    color: black;
}

.contentListContainer a:hover {
    color: #66CCB8;
}