@import url(https://fonts.googleapis.com/css?family=Kanit:300,500|Sarabun&display=swap&subset=thai);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: content-box;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
    display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
    font-size: 100%; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
    outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
    outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
    margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
    margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0; /* 1 */
    padding: 0;
    white-space: normal; /* 2 */
    *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
    line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    *height: 13px; /* 3 */
    *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */ /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
button,
input,
select,
textarea {
    color: #222;
}


::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

img {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

h1 {
  font-family: "Kanit", serif;
  font-size: 32px;
}

h2 {
  font-family: "Kanit", serif;
  font-size: 24px;
}

h3 {
  font-family: "Sarabun", serif;
  font-size: 16px;
}

p {
    font-family: "Sarabun", serif;
    font-size: 16px;
    line-height: 1.5;
}


li a.active {
  color: white!important;
  border-bottom: 2px solid #66CCB8;
}
.Centered_centered__1yJwA {
    max-width: 70vw;
    margin: 0 auto;
}

@media (max-width: 350px) {
    .Centered_centered__1yJwA {
        max-width: 90vw;
    }
}
.DashboardPage_courseContainer__2b6oQ {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
}

.DashboardPage_courseContainer__2b6oQ div {
    margin-right: 1rem;
}
.Card_container__16vRf {
  height: 160px;
  width: 380px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 5px #00000059;
  border-radius: 12px;
  display: flex;
}

.Card_colorBar__3NgdH {
    width: 80px;
    height: 100%;
    border-radius: 12px 0px 0px 12px;
}

.Card_course__3P8Cz {
    width: 100%;
    margin: 16px 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Card_bottom__3lNbG {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Card_lenght__3Ecgj {
    font-family: "Sarabun", serif;
    font-size: 16px;
}
.Button_button__17NsH {
    font-family: 'Kanit', sans-serif;
    font-weight: 400;
    font-size: 16px;
    background-color: #66CCB8;
    color: white;
    border: none;
    border-radius: .4rem;
    padding: 8px 12px;
}

.Button_buttonSmall__3zEwJ {
    font-family: 'Kanit', sans-serif;
    font-weight: 400;
    font-size: 14px;
    background-color: #66CCB8;
    color: white;
    border: none;
    border-radius: .4rem;
    padding: 8px;
}

.Button_buttonBack__ppGhT {
    font-family: 'Kanit', sans-serif;
    color: #7E7E7E;
    font-weight: 400;
    font-size: 16px;
    background-color: Transparent;
    border: none;
    border-radius: .4rem;
    padding: 8px 12px;
}
.QuizPage2_container__16jb7 {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.QuizPage2_header__1PAu6 {
    margin-bottom: 36px;
    text-align: center;
}

.QuizPage2_description__ceRlp {
    margin-top: 12px;
    font-size: 20px;
}
.Question_container__2ADXd {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Question_header__3yw05 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
}

.Question_question__2bIj3 {
    margin-right: 16px;
}

.Question_choices__1LQ0h {
    display: grid;
    grid-template-columns: 2;
    margin: 16px 48px;
}

.Question_navigation__1Yn8K {
    margin: 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.Question_back__20Iwx {
    /* display: flex;
    align-content: flex-start; */
    text-align: right;
}

.Question_next__13XeB {
    display: flex;
    align-items: flex-end;
}

p {
    line-height: 1.5;
}

.Question_headerRight__1jOzN {
    display: flex;
    flex-direction: row;
}
.Choice_container__2gHGr {
  height: 80px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 5px #00000059;
  border-radius: 12px;
  display: flex;
  margin-bottom: 16px;
}

.Choice_colorBar__25dKR {
  width: 80px;
  height: 100%;
  border-radius: 12px 0px 0px 12px;
  background-color: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Choice_colorBarSelected__25ab9 {
  color: white;
  background: transparent -webkit-gradient(linear, left top, left bottom, from(#35529e), to(#c5aacd)) 0%
    0% no-repeat padding-box;
  background: transparent linear-gradient(180deg, #35529e 0%, #c5aacd 100%) 0%
    0% no-repeat padding-box;
}

.Choice_colorBarGreen__16w4x {
    width: 80px;
    height: 100%;
    border-radius: 12px 0px 0px 12px;
    background-color: #66CCB8;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Choice_colorBarRed__V0eEA {
    width: 80px;
    height: 100%;
    border-radius: 12px 0px 0px 12px;
    background-color:#D84178;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.Choice_data__JZW21 {
  display: flex;
  align-items: center;
  margin: 12px;
}

.Choice_circle__2M561 {
  border: 7px solid #7e7e7e;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.Choice_circleWhite__1Vpz1 {
  border: 7px solid #ffffff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}


.QuestionNavigation_questionNavigationContainer__zK6_C {
    display: flex;
}
.QuestionStatus_questionStatusContainer__gXDaw {
    border: 5px solid black;
    margin: .3rem;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.QuestionStatus_questionStatusContainer__gXDaw:hover {
    background-color: #D9D9D9
}

.QuestionStatus_questionNumber__2YqsR {
    
}

.QuestionStatus_doneStatus__3ErSJ {
    background-color: #66CCB8;
}

.QuestionStatus_doneStatus__3ErSJ:hover {
    background-color: #21a68c;
}

.QuestionStatus_notMark__EdwVw {
    border: 3px black ;
}

.QuestionStatus_doneStatus__3ErSJ.QuestionStatus_marked__3po1L {
    border: 7px double white;
}

.QuestionStatus_marked__3po1L {
    border: 7px double black;
}

p {
    padding: 0!important;
}

@media (max-width: 350px) {
    .QuestionStatus_questionStatusContainer__gXDaw {
        margin: 3rem 0;
    }
  }
.SummaryPage_contentListContainer__PPWTm {
    display: flex;
    justify-content: space-evenly;
    margin: 3rem 0;
}

.SummaryPage_header__2aMGP {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.SummaryPage_header__2aMGP h1, .SummaryPage_header__2aMGP h2, .SummaryPage_header__2aMGP h3 {
    line-height: 1.5;
} 

.SummaryPage_contentList__2HVFZ {
    display: flex;
    flex-direction: column;
    font-family: 'Sarabun';
    list-style: circle;
    margin: 1rem auto;
    line-height: 1.8;
}

.SummaryPage_contentList__2HVFZ h2 {
    margin-bottom: 1rem;
}

.SummaryPage_contentWrapper__2pJ5Y {
    margin: 5rem auto;
}

.SummaryPage_contentListContainer__PPWTm a {
    color: black;
    text-decoration: underline;
}

.SummaryPage_contentListContainer__PPWTm a:visited {
    color: black;
}

.SummaryPage_contentListContainer__PPWTm a:hover {
    color: #66CCB8;
}



.Content1Page_contentContainer__23adp {
    line-height: 1.5;
}

.Content1Page_contentContainer__23adp h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

hr {
    margin-bottom: 3rem;
}
.Content2Page_contentContainer__24QoQ {
    line-height: 1.5;
}

.Content2Page_contentContainer__24QoQ h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

hr {
    margin-bottom: 3rem;
}
.Content3Page_contentContainer__1cFeK {
    line-height: 1.5;
}

.Content3Page_contentContainer__1cFeK h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

hr {
    margin-bottom: 3rem;
}
.Navbar_navigation__BFTGz {
    min-height: 70px;
    margin-bottom: 3rem;
    box-shadow: 3px 3px 5px #00000059;
    font-family: 'Kanit', sans-serif;
    font-weight: 300;
    font-size: 1.2rem;
    color: #C9C9C9;
    background: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
}

.Navbar_menuContainer__1VQhI {
    height: 100%;
    display: flex;
    align-items: center;  
    padding-top: .2rem;  
}

.Navbar_menuContainer__1VQhI li {
    margin-left: 1rem;
}

.Navbar_menuContainer__1VQhI li a {
    text-decoration: none;
}

.Navbar_menuContainer__1VQhI li a:visited {
    color: #C9C9C9;
}

.Navbar_menuContainer__1VQhI li a:hover {
    color: white;
    border-bottom: 2px solid #66CCB8;
}

.Footer_footerContainer__2MF5j {
    max-height: 100px;
    min-height: 30px;
    background: #3A3A3A;
    color: white;
}

.Footer_flex__UU6Be {
    display: flex;
}

p {
    padding-top: 1rem;
    padding-bottom: 2rem;
}
