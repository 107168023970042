.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
}

.question {
    margin-right: 16px;
}

.choices {
    display: grid;
    grid-template-columns: 2;
    margin: 16px 48px;
}

.navigation {
    margin: 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.back {
    /* display: flex;
    align-content: flex-start; */
    text-align: right;
}

.next {
    display: flex;
    align-items: flex-end;
}

p {
    line-height: 1.5;
}

.headerRight {
    display: flex;
    flex-direction: row;
}