@import url("https://fonts.googleapis.com/css?family=Kanit:300,500|Sarabun&display=swap&subset=thai");

h1 {
  font-family: "Kanit", serif;
  font-size: 32px;
}

h2 {
  font-family: "Kanit", serif;
  font-size: 24px;
}

h3 {
  font-family: "Sarabun", serif;
  font-size: 16px;
}

p {
    font-family: "Sarabun", serif;
    font-size: 16px;
    line-height: 1.5;
}


li a.active {
  color: white!important;
  border-bottom: 2px solid #66CCB8;
}