.questionStatusContainer {
    border: 5px solid black;
    margin: .3rem;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.questionStatusContainer:hover {
    background-color: #D9D9D9
}

.questionNumber {
    
}

.doneStatus {
    background-color: #66CCB8;
}

.doneStatus:hover {
    background-color: #21a68c;
}

.notMark {
    border: 3px black ;
}

.doneStatus.marked {
    border: 7px double white;
}

.marked {
    border: 7px double black;
}

p {
    padding: 0!important;
}

@media (max-width: 350px) {
    .questionStatusContainer {
        margin: 3rem 0;
    }
  }